var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { ref: "formInvoice", attrs: { name: "formInvoice" } },
        [
          _vm.isDevolution || _vm.hasInvoiceReferences
            ? _c(
                "b-card-actions",
                {
                  attrs: {
                    "show-loading": _vm.loading || _vm.loadingInvoiceData,
                    "action-collapse": "",
                    title:
                      _vm.invoice.invoiceReferences.length > 1
                        ? _vm.$t("Documentos Fiscais Referenciados")
                        : _vm.$t("Documento Fiscal Referenciado"),
                  },
                },
                [
                  _c(
                    "b-row",
                    _vm._l(
                      _vm.invoice.invoiceReferences,
                      function (invoiceRef) {
                        return _c(
                          "b-col",
                          {
                            key: "" + invoiceRef.id,
                            attrs: { md: "6", lg: "4" },
                          },
                          [
                            _c(
                              "b-card",
                              { staticClass: "bordered" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "10" } },
                                      [
                                        _c("FormulateInput", {
                                          attrs: {
                                            value: invoiceRef.accessKey,
                                            type: "label",
                                            label: _vm.$t("Chave de acesso"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "2" } },
                                      [
                                        _c("FormulateInput", {
                                          attrs: {
                                            value: invoiceRef.invoiceModel,
                                            type: "label",
                                            label: _vm.$t("Tipo"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading || _vm.loadingInvoiceData,
                "action-collapse": "",
                title: _vm.$t("Dados da Nota Fiscal"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _vm.isEditPage ||
                  _vm.readOnly ||
                  _vm.isDevolution ||
                  _vm.isAdjustStock ||
                  _vm.isInvoiceComplementary ||
                  _vm.isInvoiceReturnFromCouponCategory
                    ? _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "invoice-receive_category",
                              value:
                                _vm.invoiceCategoryLabel[
                                  _vm.invoice.invoiceCategory
                                ] +
                                " | " +
                                _vm.invoiceModelLabelShort[_vm.invoice.model],
                              type: "label",
                              label: _vm.$t("Tipo de nota"),
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "b-col",
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "invoice-receive_category",
                              type: "radio",
                              options: _vm.invoiceCategoryToRadio,
                              label: _vm.$t(
                                "Qual tipo de nota deseja emitir ?"
                              ),
                              "element-class": ["d-flex", "mt-1", "flex-wrap"],
                            },
                            on: { input: _vm.onChangeInvoiceCategory },
                            model: {
                              value: _vm.invoice.invoiceCategory,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoice, "invoiceCategory", $$v)
                              },
                              expression: "invoice.invoiceCategory",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm.invoice.saleId
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "sale_id",
                              type: "label",
                              label: _vm.$t("Venda"),
                              "router-to": {
                                name: "order-read-only",
                                params: { id: _vm.invoice.saleId },
                              },
                            },
                            model: {
                              value: _vm.invoice.saleId,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoice, "saleId", $$v)
                              },
                              expression: "invoice.saleId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.invoice.stockInventory
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "stock_inventory_id",
                              type: "label",
                              label: _vm.$t("Inventário"),
                              "router-to": {
                                name: "stock-inventory-read-only",
                                params: { id: _vm.invoice.stockInventory.id },
                              },
                            },
                            model: {
                              value: _vm.invoice.stockInventory.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.invoice.stockInventory, "id", $$v)
                              },
                              expression: "invoice.stockInventory.id",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.isEditPage
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "invoice-status",
                              name: "invoiceStatus",
                              value: _vm.statusInvoiceLabel[_vm.invoice.status],
                              instruction: _vm.statusInstruction,
                              type: "label",
                              label: _vm.$t("Status"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "invoice-access_key",
                              name: "accessKey",
                              value: _vm.documentDetail.accessKey,
                              type: "label",
                              label: _vm.$t("Chave de acesso"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "5" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "invoice-return_message",
                              name: "returnMessage",
                              value: _vm.documentDetail.returnMessage,
                              type: "label",
                              label: _vm.$t("Mensagem de Retorno"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasInvoiceCategory
                ? _c("invoice-data-form", {
                    ref: "invoiceDataForm",
                    attrs: {
                      "is-read-only":
                        _vm.readOnly || _vm.isInvoiceComplementary,
                      invoice: _vm.invoice,
                      "is-purchase-devolution": _vm.isDevolution,
                    },
                    on: {
                      "get-store-by-id": _vm.getStoreById,
                      "clean-store": _vm.cleanInvoiceStore,
                      "clean-nature-operation": _vm.cleanNatureOperationCfop,
                      "update-invoice-item-totals": _vm.onUpdateItemsTotals,
                      "set-nature-operation": _vm.onNatureOperationSelected,
                      "load-tax-combos": _vm.loadTaxCombos,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.hasInvoiceCategory
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          staticClass: "h-345",
                          attrs: {
                            "show-loading": _vm.loading || _vm.loadingPerson,
                            "action-collapse": "",
                            title: _vm.$t("Destinatário"),
                          },
                        },
                        [
                          _vm.isReturnCategory
                            ? [
                                _c("person-form", {
                                  ref: "receiverForm",
                                  attrs: {
                                    id: "supplier_invoice",
                                    name: "supplierDestination",
                                    "person-object": _vm.invoice,
                                    "is-read-only":
                                      _vm.readOnly ||
                                      _vm.isDevolution ||
                                      _vm.isAdjustStock ||
                                      _vm.isInvoiceComplementary,
                                    "is-supplier": "",
                                    "list-to-hide":
                                      _vm.getDestinationOptionToHide,
                                  },
                                  on: {
                                    "get-person-by-id": function (v) {
                                      return _vm.getPersonById(
                                        v,
                                        _vm.invoiceCategoryEnum
                                          .PuchaseOrderReturn
                                      )
                                    },
                                    "clean-person": _vm.cleanInvoiceSupplier,
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.isSaleCategory ||
                          _vm.isProductBonusCategory ||
                          _vm.isStockLossesCategory ||
                          _vm.isInvoiceFromCouponCategory ||
                          _vm.isInvoiceComplementaryCategory ||
                          _vm.isInvoiceReturnFromCouponCategory ||
                          _vm.isSaleFromPdvCategory
                            ? [
                                _c("person-form", {
                                  ref: "receiverForm",
                                  attrs: {
                                    id: "customer_invoice",
                                    name: "customerDestination",
                                    "person-object": _vm.invoice,
                                    "is-read-only":
                                      _vm.readOnly ||
                                      _vm.isDevolution ||
                                      _vm.isAdjustStock ||
                                      _vm.isStockLossesCategory ||
                                      _vm.isInvoiceComplementary ||
                                      _vm.isInvoiceReturnFromCouponCategory,
                                    "is-customer": "",
                                    "is-customer-with-store":
                                      _vm.isProductBonusCategory,
                                    "list-to-hide":
                                      _vm.getDestinationOptionToHide,
                                  },
                                  on: {
                                    "get-person-by-id": function (v) {
                                      return _vm.getPersonById(
                                        v,
                                        _vm.invoiceCategoryEnum.Sale
                                      )
                                    },
                                    "clean-person": _vm.cleanInvoiceCustomer,
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.isStoreCategory || _vm.isStoreLendingCategory
                            ? [
                                _c("person-form", {
                                  ref: "receiverForm",
                                  attrs: {
                                    id: "store_invoice",
                                    name: "storeInvoice",
                                    "person-object": _vm.invoice,
                                    "is-read-only":
                                      _vm.readOnly ||
                                      _vm.isDevolution ||
                                      _vm.isAdjustStock ||
                                      _vm.isInvoiceComplementary,
                                    "is-store": "",
                                    "list-to-hide":
                                      _vm.getDestinationOptionToHide,
                                  },
                                  on: {
                                    "get-person-by-document": function (v) {
                                      return _vm.getPersonByDocument(v)
                                    },
                                    "clean-person": _vm.cleanInvoiceCustomer,
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: {
                            "show-loading": _vm.loading || _vm.loadingItems,
                            "action-collapse": "",
                            title: _vm.$t("Itens do Pedido"),
                          },
                        },
                        [
                          _c("items-form-table", {
                            ref: "invoiceItemsTable",
                            attrs: {
                              invoice: _vm.invoice,
                              "is-read-only": _vm.readOnly,
                              "is-invoice-complementary":
                                _vm.isInvoiceComplementary,
                              "is-return": _vm.isDevolution,
                              "is-output": _vm.isOutput(),
                            },
                            on: {
                              "add-item": _vm.stAddItem,
                              "update-item": _vm.stUpdateItem,
                              "update-product-item": _vm.onSelectProduct,
                              "update-invoice-item-totals":
                                _vm.onUpdateItemsTotals,
                              "remove-item": _vm.onRemoveItem,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          staticClass: "h-345",
                          attrs: {
                            "show-loading": _vm.loading,
                            "action-collapse": "",
                            title: _vm.$t("Valores da Nota Fiscal"),
                          },
                        },
                        [
                          _c("invoice-values-form", {
                            ref: "invoiceValueForm",
                            attrs: {
                              invoice: _vm.invoice,
                              "is-read-only":
                                _vm.readOnly ||
                                _vm.isInvoiceReturnFromCouponCategory,
                            },
                            on: {
                              "update-invoice-item-totals":
                                _vm.onUpdateItemsTotals,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.invoice.isFromSales
                    ? _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-card-actions",
                            {
                              attrs: {
                                "show-loading": _vm.loading,
                                "action-collapse": "",
                                title: _vm.$t("Pagamentos"),
                              },
                            },
                            [
                              _c("payments-form-table", {
                                ref: "invoicePaymentsTable",
                                attrs: {
                                  invoice: _vm.invoice,
                                  "read-only":
                                    _vm.readOnly ||
                                    _vm.isAdjustStock ||
                                    _vm.isPaymentValuesReadOnly ||
                                    _vm.isInvoiceComplementary ||
                                    _vm.isInvoiceReturnFromCouponCategory,
                                },
                                on: {
                                  "add-payment": _vm.addPayment,
                                  "remove-payment": _vm.removePayment,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "mb-2", attrs: { md: "12" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: {
                            "show-loading": _vm.loading,
                            "action-collapse": "",
                            title: _vm.$t("Eventos da NF-e"),
                          },
                        },
                        [
                          _c("events-form-table", {
                            ref: "invoiceEventsTable",
                            attrs: { invoice: _vm.invoice },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              actions: _vm.actions,
              "fixed-tooltip": true,
              busy: _vm.loading || _vm.loadingItems || _vm.loadingInvoiceData,
            },
            on: {
              cancel: _vm.onCancelSubmit,
              "save-draft": _vm.onSaveDraft,
              "invalidate-invoice": _vm.onInvalidateInvoice,
              "delete-invoice": _vm.onDeleteInvoice,
              "send-invoice": _vm.onSendInvoice,
              "consult-invoice": _vm.onConsultInvoice,
              "consult-protocol": _vm.onConsultProtocol,
              "download-pdf": _vm.onDownloadPdf,
              "download-xml": _vm.onDownloadXml,
              "cancel-invoice": function () {
                return _vm.onEventInvoice("cancel")
              },
              "correction-letter-invoice": function () {
                return _vm.onEventInvoice("correctionLetter")
              },
              "generate-invoice-complementary":
                _vm.onGenerateInvoiceComplementary,
            },
          }),
        ],
        1
      ),
      _c("consult-invoice-modal", { ref: "consultInvoiceModal" }),
      _c(
        "e-modal-custom-form",
        {
          ref: "modalEventInvoice",
          attrs: { title: _vm.modalInvoiceEventTitle },
        },
        [
          _c("FormulateInput", {
            attrs: {
              id: "invoice_event-justification",
              type: "textarea",
              label: _vm.$t("Justificativa"),
              validation: _vm.modalInvoiceEventJustificationValiation,
            },
            model: {
              value: _vm.invoiceEventForm.justification,
              callback: function ($$v) {
                _vm.$set(_vm.invoiceEventForm, "justification", $$v)
              },
              expression: "invoiceEventForm.justification",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }