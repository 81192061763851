<template>
  <section>
    <FormulateForm
      ref="formInvoice"
      name="formInvoice"
    >
      <b-card-actions
        v-if="isDevolution || hasInvoiceReferences"
        :show-loading="loading || loadingInvoiceData"
        action-collapse
        :title="
          invoice.invoiceReferences.length > 1
            ? $t('Documentos Fiscais Referenciados')
            : $t('Documento Fiscal Referenciado')
        "
      >
        <b-row>
          <b-col
            v-for="invoiceRef in invoice.invoiceReferences"
            :key="`${invoiceRef.id}`"
            md="6"
            lg="4"
          >
            <b-card class="bordered">
              <b-row>
                <b-col md="10">
                  <FormulateInput
                    :value="invoiceRef.accessKey"
                    type="label"
                    :label="$t('Chave de acesso')"
                  />
                </b-col>
                <b-col md="2">
                  <FormulateInput
                    :value="invoiceRef.invoiceModel"
                    type="label"
                    :label="$t('Tipo')"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        :show-loading="loading || loadingInvoiceData"
        action-collapse
        :title="$t('Dados da Nota Fiscal')"
      >
        <b-row>
          <b-col
            v-if="
              isEditPage ||
                readOnly ||
                isDevolution ||
                isAdjustStock ||
                isInvoiceComplementary ||
                isInvoiceReturnFromCouponCategory
            "
            md="4"
          >
            <FormulateInput
              id="invoice-receive_category"
              :value="`${invoiceCategoryLabel[invoice.invoiceCategory]} | ${
                invoiceModelLabelShort[invoice.model]
              }`"
              type="label"
              :label="$t('Tipo de nota')"
            />
          </b-col>
          <b-col v-else>
            <FormulateInput
              id="invoice-receive_category"
              v-model="invoice.invoiceCategory"
              type="radio"
              :options="invoiceCategoryToRadio"
              :label="$t('Qual tipo de nota deseja emitir ?')"
              :element-class="['d-flex', 'mt-1', 'flex-wrap']"
              @input="onChangeInvoiceCategory"
            />
          </b-col>

          <b-col
            v-if="invoice.saleId"
            md="2"
          >
            <FormulateInput
              id="sale_id"
              v-model="invoice.saleId"
              type="label"
              :label="$t('Venda')"
              :router-to="{
                name: 'order-read-only',
                params: { id: invoice.saleId },
              }"
            />
          </b-col>
          <b-col
            v-if="invoice.stockInventory"
            md="2"
          >
            <FormulateInput
              id="stock_inventory_id"
              v-model="invoice.stockInventory.id"
              type="label"
              :label="$t('Inventário')"
              :router-to="{
                name: 'stock-inventory-read-only',
                params: { id: invoice.stockInventory.id },
              }"
            />
          </b-col>
        </b-row>

        <b-row v-if="isEditPage">
          <b-col md="3">
            <FormulateInput
              id="invoice-status"
              name="invoiceStatus"
              :value="statusInvoiceLabel[invoice.status]"
              :instruction="statusInstruction"
              type="label"
              :label="$t('Status')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="invoice-access_key"
              name="accessKey"
              :value="documentDetail.accessKey"
              type="label"
              :label="$t('Chave de acesso')"
            />
          </b-col>
          <b-col md="5">
            <FormulateInput
              id="invoice-return_message"
              name="returnMessage"
              :value="documentDetail.returnMessage"
              type="label"
              :label="$t('Mensagem de Retorno')"
            />
          </b-col>
        </b-row>
        <invoice-data-form
          v-if="hasInvoiceCategory"
          ref="invoiceDataForm"
          :is-read-only="readOnly || isInvoiceComplementary"
          :invoice="invoice"
          :is-purchase-devolution="isDevolution"
          @get-store-by-id="getStoreById"
          @clean-store="cleanInvoiceStore"
          @clean-nature-operation="cleanNatureOperationCfop"
          @update-invoice-item-totals="onUpdateItemsTotals"
          @set-nature-operation="onNatureOperationSelected"
          @load-tax-combos="loadTaxCombos"
        />
      </b-card-actions>

      <b-row v-if="hasInvoiceCategory">
        <b-col md="12">
          <b-card-actions
            :show-loading="loading || loadingPerson"
            action-collapse
            :title="$t('Destinatário')"
            class="h-345"
          >
            <template v-if="isReturnCategory">
              <person-form
                id="supplier_invoice"
                ref="receiverForm"
                name="supplierDestination"
                :person-object="invoice"
                :is-read-only="readOnly || isDevolution || isAdjustStock || isInvoiceComplementary"
                is-supplier
                :list-to-hide="getDestinationOptionToHide"
                @get-person-by-id="(v) => getPersonById(v, invoiceCategoryEnum.PuchaseOrderReturn)"
                @clean-person="cleanInvoiceSupplier"
              />
            </template>
            <template
              v-if="
                isSaleCategory ||
                  isProductBonusCategory ||
                  isStockLossesCategory ||
                  isInvoiceFromCouponCategory ||
                  isInvoiceComplementaryCategory ||
                  isInvoiceReturnFromCouponCategory ||
                  isSaleFromPdvCategory
              "
            >
              <person-form
                id="customer_invoice"
                ref="receiverForm"
                name="customerDestination"
                :person-object="invoice"
                :is-read-only="
                  readOnly ||
                    isDevolution ||
                    isAdjustStock ||
                    isStockLossesCategory ||
                    isInvoiceComplementary ||
                    isInvoiceReturnFromCouponCategory
                "
                is-customer
                :is-customer-with-store="isProductBonusCategory"
                :list-to-hide="getDestinationOptionToHide"
                @get-person-by-id="(v) => getPersonById(v, invoiceCategoryEnum.Sale)"
                @clean-person="cleanInvoiceCustomer"
              />
            </template>
            <template v-if="isStoreCategory || isStoreLendingCategory">
              <person-form
                id="store_invoice"
                ref="receiverForm"
                name="storeInvoice"
                :person-object="invoice"
                :is-read-only="readOnly || isDevolution || isAdjustStock || isInvoiceComplementary"
                is-store
                :list-to-hide="getDestinationOptionToHide"
                @get-person-by-document="(v) => getPersonByDocument(v)"
                @clean-person="cleanInvoiceCustomer"
              />
            </template>
          </b-card-actions>
        </b-col>

        <b-col md="12">
          <b-card-actions
            :show-loading="loading || loadingItems"
            action-collapse
            :title="$t('Itens do Pedido')"
          >
            <items-form-table
              ref="invoiceItemsTable"
              :invoice="invoice"
              :is-read-only="readOnly"
              :is-invoice-complementary="isInvoiceComplementary"
              :is-return="isDevolution"
              :is-output="isOutput()"
              @add-item="stAddItem"
              @update-item="stUpdateItem"
              @update-product-item="onSelectProduct"
              @update-invoice-item-totals="onUpdateItemsTotals"
              @remove-item="onRemoveItem"
            />
          </b-card-actions>
        </b-col>

        <b-col md="12">
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Valores da Nota Fiscal')"
            class="h-345"
          >
            <invoice-values-form
              ref="invoiceValueForm"
              :invoice="invoice"
              :is-read-only="readOnly || isInvoiceReturnFromCouponCategory"
              @update-invoice-item-totals="onUpdateItemsTotals"
            />
          </b-card-actions>
        </b-col>

        <b-col
          v-if="!invoice.isFromSales"
          md="12"
        >
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Pagamentos')"
          >
            <payments-form-table
              ref="invoicePaymentsTable"
              :invoice="invoice"
              :read-only="
                readOnly ||
                  isAdjustStock ||
                  isPaymentValuesReadOnly ||
                  isInvoiceComplementary ||
                  isInvoiceReturnFromCouponCategory
              "
              @add-payment="addPayment"
              @remove-payment="removePayment"
            />
          </b-card-actions>
        </b-col>

        <b-col
          md="12"
          class="mb-2"
        >
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Eventos da NF-e')"
          >
            <events-form-table
              ref="invoiceEventsTable"
              :invoice="invoice"
            />
          </b-card-actions>
        </b-col>
      </b-row>

      <fab
        :main-tooltip="$t('Ações')"
        main-icon="keyboard_command_key"
        :actions="actions"
        :fixed-tooltip="true"
        :busy="loading || loadingItems || loadingInvoiceData"
        @cancel="onCancelSubmit"
        @save-draft="onSaveDraft"
        @invalidate-invoice="onInvalidateInvoice"
        @delete-invoice="onDeleteInvoice"
        @send-invoice="onSendInvoice"
        @consult-invoice="onConsultInvoice"
        @consult-protocol="onConsultProtocol"
        @download-pdf="onDownloadPdf"
        @download-xml="onDownloadXml"
        @cancel-invoice="() => onEventInvoice('cancel')"
        @correction-letter-invoice="() => onEventInvoice('correctionLetter')"
        @generate-invoice-complementary="onGenerateInvoiceComplementary"
      />
    </FormulateForm>

    <consult-invoice-modal ref="consultInvoiceModal" />

    <e-modal-custom-form
      ref="modalEventInvoice"
      :title="modalInvoiceEventTitle"
    >
      <FormulateInput
        id="invoice_event-justification"
        v-model="invoiceEventForm.justification"
        type="textarea"
        :label="$t('Justificativa')"
        :validation="modalInvoiceEventJustificationValiation"
      />
    </e-modal-custom-form>
  </section>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { downloader, invoiceDomains, paymentTypes, uploader } from '@/mixins'
import fab from '@/views/components/FAB.vue'
import EModalCustomForm from '@/views/components/EModalCustomForm.vue'
import { exceptionTypeEnum, getApiErrorData } from '@/utils/error-utils'
import delegablePermissions from '@/utils/delegable-permissions'
import PersonForm from './components/PersonForm.vue'
import InvoiceValuesForm from './components/InvoiceValuesForm.vue'
import InvoiceDataForm from './components/InvoiceDataForm.vue'
import ItemsFormTable from './components/ItemsFormTable.vue'
import PaymentsFormTable from './components/PaymentsFormTable.vue'
import EventsFormTable from './components/EventsFormTable.vue'
import ConsultInvoiceModal from './components/ConsultInvoiceModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardActions,
    PersonForm,
    InvoiceValuesForm,
    fab,
    InvoiceDataForm,
    ItemsFormTable,
    PaymentsFormTable,
    EventsFormTable,
    ConsultInvoiceModal,
    EModalCustomForm,
  },

  mixins: [uploader, downloader, invoiceDomains, paymentTypes],

  data() {
    return {
      loading: false,
      loadingPerson: false,
      loadingInvoiceData: false,
      loadingItems: false,
      modalInvoiceEventTitle: '',
      modalInvoiceEventJustificationValiation: '',
      invoiceEventForm: {
        justification: '',
      },
      wasSelectedCategory: false,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/invoice/invoice/invoiceMaintain', {
      invoice: 'invoice',
    }),
    ...mapGetters('pages/invoice/invoice/invoiceMaintain', [
      'isDevolution',
      'isInvoiceFromCouponCategory',
      'isInvoiceReturnFromCouponCategory',
      'isInvoiceComplementaryCategory',
      'isAdjustStock',
      'isGeneratingInReadOnlyMode',
      'isSaleCategory',
      'isSaleFromPdvCategory',
      'isReturnCategory',
      'isStoreCategory',
      'isStockLossesCategory',
      'isProductBonusCategory',
      'isStoreLendingCategory',
      'isPaymentValuesReadOnly',
    ]),

    invoiceCategory() {
      return this.invoice.invoiceCategory
    },

    documentDetail() {
      return {
        accessKey: this.invoice.satDetail?.cfeAccessKey || this.invoice.nfeDetail?.accessKey,
        returnMessage:
          this.invoice.satDetail?.displayMessage || this.invoice.nfeDetail?.displayMessage,
      }
    },

    readOnly() {
      return (
        this.canConsultInvoice ||
        this.invalidatedInvoice ||
        this.isGeneratingInReadOnlyMode ||
        this.isInvoiceFromCouponCategory
      )
    },

    getDestinationOptionToHide() {
      if (this.invoice?.store) {
        return [{ document: this.invoice?.store?.cnpj }]
      }
      return []
    },

    isEditPage() {
      return !!this.$route.params?.id
    },
    canSendInvoice() {
      const { SUCCESS_FINISHED, CANCELED, WAITING_FOR_RESPONSE, DENIED } = this.statusInvoiceEnum
      const { status: invoiceStatus } = this.invoice
      return (
        invoiceStatus !== SUCCESS_FINISHED &&
        invoiceStatus !== CANCELED &&
        invoiceStatus !== WAITING_FOR_RESPONSE &&
        invoiceStatus !== DENIED
      )
    },
    isInvoiceComplementary() {
      return this.invoice.purposeType === 'COMPLEMENTARY'
    },
    isNFeModel() {
      return this.invoice.model === this.invoiceModelEnum.NFE
    },
    invalidatedInvoice() {
      return this.invoice.status === this.statusInvoiceEnum.INVALIDATED
    },
    isInvoiceDenied(){
      return this.invoice.status === this.statusInvoiceEnum.DENIED
    },
    canConsultInvoice() {
      const { SUCCESS_FINISHED, CANCELED, DENIED } = this.statusInvoiceEnum
      return this.invoice.status === SUCCESS_FINISHED || this.invoice.status === CANCELED || this.invoice.status === DENIED
    },
    canConsultProtocol() {
      const { WAITING_FOR_RESPONSE } = this.statusInvoiceEnum
      return this.invoice.status === WAITING_FOR_RESPONSE
    },
    canSendEventInvoice() {
      const { SUCCESS_FINISHED } = this.statusInvoiceEnum
      return this.invoice.status === SUCCESS_FINISHED
    },

    hasInvoiceCategory() {
      return !!this.invoice.invoiceCategory
    },
    hasInvoiceReferences() {
      return (
        Array.isArray(this.invoice.invoiceReferences) && this.invoice.invoiceReferences.length > 0
      )
    },
    hasPendencies() {
      return (
        !!this.invoice.items.find(item => !item.erpSku?.sku?.id) ||
        !!this.invoice.payments.find(payment => !payment.managementAccountId)
      )
    },
    storeLabel() {
      return this.invoice?.store?.name
    },
    statusInstruction() {
      switch (this.invoice.status) {
        case this.statusInvoiceEnum.ISSUED_IN_CONTINGENCY:
          return this.$t('INVOICE.CONTINGENCY_INSTRUCTIONS')
        case this.statusInvoiceEnum.WAITING_FOR_RESPONSE:
          return this.$t('INVOICE.WAITING_FOR_RESPONSE_INSTRUCTIONS')
        default:
          return null
      }
    },

    actions() {
      const { XML_GENERATED, REJECTED, WAITING_FOR_RESPONSE, DRAFT } = this.statusInvoiceEnum

      return [
        {
          name: 'cancel',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
        },
        {
          name: 'save-draft',
          icon: 'save',
          color: 'green',
          tooltip: this.$t('Salvar como Rascunho'),
          hide:
            (this.readOnly &&
              !(this.isGeneratingInReadOnlyMode || this.isInvoiceFromCouponCategory)) ||
            this.invalidatedInvoice ||
            this.invoice.status === WAITING_FOR_RESPONSE ||
            !this.isNFeModel,
        },
        {
          name: 'invalidate-invoice',
          icon: 'cancel',
          color: 'red',
          tooltip: this.$t('Inutilizar Numeração'),
          hide:
            !this.invoice?.id ||
            (this.invoice.status && ![XML_GENERATED, REJECTED].includes(this.invoice.status)),
        },
        {
          name: 'delete-invoice',
          icon: 'delete',
          color: 'red',
          tooltip: this.$t('Deletar Nota Fiscal'),
          hide:
            !this.invoice?.id ||
            this.invoice?.invoiceNumber ||
            (this.invoice.status && ![DRAFT].includes(this.invoice.status)),
        },
        {
          name: 'send-invoice',
          icon: 'cloud_upload',
          color: 'green',
          tooltip: this.$t('Salvar e Enviar'),
          hide: !this.canSendInvoice || this.invalidatedInvoice || !this.isNFeModel,
        },
        {
          name: 'consult-invoice',
          icon: 'search',
          color: 'green',
          tooltip: this.$t('Consultar Status da Nota'),
          hide: !this.canConsultInvoice || this.invalidatedInvoice,
        },
        {
          name: 'consult-protocol',
          icon: 'search',
          color: 'green',
          tooltip: this.$t('Consultar Protocolo'),
          hide: !this.canConsultProtocol,
        },
        {
          name: 'download-pdf',
          icon: 'print',
          color: 'green',
          tooltip: this.$t('Download do PDF'),
          hide: !this.canConsultInvoice || this.invalidatedInvoice || !this.isNFeModel || this.isInvoiceDenied,
        },
        {
          name: 'download-xml',
          icon: 'download',
          color: 'green',
          tooltip: this.$t('Download Xml'),
          hide: !this.canConsultInvoice || this.invalidatedInvoice,
        },
        {
          name: 'cancel-invoice',
          icon: 'close',
          color: 'red',
          tooltip: this.$t('Cancelar Nota'),
          hide: !this.canSendEventInvoice || this.invalidatedInvoice || !this.isNFeModel || this.isInvoiceDenied,
        },
        {
          name: 'correction-letter-invoice',
          icon: 'edit_note',
          color: 'blue',
          tooltip: this.$t('Carta de correção'),
          hide: !this.canSendEventInvoice || this.invalidatedInvoice || !this.isNFeModel || this.isInvoiceDenied,
        },
        {
          name: 'generate-invoice-complementary',
          icon: 'edit_note',
          color: 'green',
          tooltip: this.$t('Gerar Nota Complementar'),
          hide:
            !this.canConsultInvoice ||
            this.invalidatedInvoice ||
            this.isInvoiceDenied ||
            !this.isNFeModel ||
            this.invoice.purposeType === 'COMPLEMENTARY',
        },
      ].filter(a => !a.hide)
    },
  },

  watch: {
    async invoiceCategory(val, oldVal) {
      if (!this.loading && this.wasSelectedCategory) {
        if (oldVal !== null) {
          const confirm = await this.confirm({
            title: this.$t('Confirmar alteração? Dados serão resetados'),
            text: this.$t(
              `Serão resetados os dados em Destinatário, Pagamentos e Natureza da operação.`
            ),
          })

          if (!confirm) {
            this.wasSelectedCategory = false
            this.invoice.invoiceCategory = oldVal
            return
          }
        }

        const { PuchaseOrderReturn, StockLosses, ProductBonus, StoreLending } =
          this.invoiceCategoryEnum
        const { WITHOUT_PAYMENT } = this.paymentTypeEnum

        if ([StockLosses, ProductBonus, StoreLending].includes(val)) {
          if (!this.getPaymentMethodByMethod(WITHOUT_PAYMENT)) {
            this.showWarning({ message: this.$t('PAYMENT_METHOD.NOT_FOUND.WITHOUT_PAYMENT') })
          }
        }

        if (val === PuchaseOrderReturn) {
          await this.cleanInvoiceSupplier()
        } else if (val != null) {
          await this.cleanInvoiceCustomer()
        }

        if (this.$refs.receiverForm) {
          this.$refs.receiverForm.reset()
        }

        await this.onChangeStore()
        await this.stCleanNatureOperation()
        await this.cleanInvoicePayments()
        await this.stUpdateInvoicePaymentValues()

        if (this.isStockLossesCategory && this.invoice?.store?.cnpj) {
          this.getPersonByDocument(this.invoice?.store?.cnpj)
        }
      }
    },
    isAdjustStock(val) {
      if (val && !this.invoice?.payments?.length) {
        const withouPayment = this.getPaymentMethodByMethod(this.paymentTypeEnum.WITHOUT_PAYMENT)
        this.addPayment({ paymentMethodId: withouPayment?.id || null })
      }
    },
  },

  created() {
    if (this.$route.params?.id) this.cleanState()
  },

  async mounted() {
    this.fetchManagementAccounts()
    if (this.$route.params?.id) {
      await this.loadInvoice()
      if (this.invoiceCategory === this.invoiceCategoryEnum.Transference) {
        this.$refs.receiverForm.store = this.$store.state.app.stores
          .find(store => store.cnpj === this.invoice?.customer?.document)
          ?.id?.toString()
      }
    }
  },

  methods: {
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),
    ...mapActions('common/managementAccounts', ['fetchManagementAccounts']),
    ...mapActions('pages/invoice/invoice/invoiceMaintain', {
      saveInvoiceDraft: 'saveInvoiceDraft',
      invalidateInvoice: 'invalidateInvoice',
      stInvoiceManage: 'invoiceManage',
      stInvoiceEvent: 'invoiceEvent',
      stInvoiceDownload: 'invoiceDownload',
      fetchInvoice: 'fetchInvoice',
      stFetchPersonById: 'fetchPersonById',
      stFetchPersonByDocument: 'fetchPersonByDocument',
      stFetchStoreById: 'fetchStoreById',
      stGenerateComplementary: 'generateComplementary',

      stAddItem: 'addItem',
      stUpdateItem: 'updateItem',
      stRemoveItem: 'removeItem',

      stUpdateItemsOfStore: 'updateItemsOfStore',
      stCalculateInvoiceTotals: 'calculateInvoiceTotals',
      stEnrichItemWithTax: 'enrichItemWithTax',
      stCalculateInvoiceItems: 'calculateInvoiceItems',

      addPayment: 'addPayment',
      removePayment: 'removePayment',
      stUpdateInvoicePaymentValues: 'updateInvoicePaymentValues',

      cleanInvoiceSupplier: 'cleanInvoiceSupplier',
      cleanInvoiceCustomer: 'cleanInvoiceCustomer',
      cleanInvoicePayments: 'cleanInvoicePayments',
      cleanInvoiceStore: 'cleanInvoiceStore',
      stCleanNatureOperation: 'cleanNatureOperation',
      cleanNatureOperationCfop: 'cleanNatureOperationCfop',
      setNatureOperationCfop: 'setNatureOperationCfop',

      cleanState: 'cleanState',
      stSetInvoiceCategory: 'setInvoiceCategory',
    }),

    async loadInvoice() {
      try {
        this.loading = true
        await this.fetchInvoice(this.$route.params?.id)
      } catch (error) {
        this.showError({
          error,
          message: this.$t('Não foi possível obter os dados da Nota Fiscal'),
        })
        this.$router.back()
      } finally {
        this.loading = false
      }
    },
    async loadTaxCombos(callback) {
      try {
        this.loadingInvoiceData = true
        await this.fetchTaxClassificationCombos()
        if (typeof callback === 'function') {
          callback()
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingInvoiceData = false
      }
    },

    onChangeInvoiceCategory() {
      this.wasSelectedCategory = true
    },

    async onNatureOperationSelected(cfop) {
      this.setNatureOperationCfop(cfop)

      if (this.canSendInvoice) {
        await this.onUpdateItemsTotals()
      } else {
        await this.stCalculateInvoiceTotals()
      }
    },
    async onSelectProduct(item) {
      if (this.loading) return
      try {
        this.loadingItems = true
        await this.stEnrichItemWithTax({ item })
        await this.onUpdateItemsTotals()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingItems = false
      }
    },
    async onUpdateItemsTotals() {
      if (this.loading) return
      try {
        this.loadingItems = true
        await this.stCalculateInvoiceItems()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingItems = false
      }
    },
    async onRemoveItem(item) {
      await this.stRemoveItem(item)
      if (!item.sku) return
      await this.onUpdateItemsTotals()
    },

    async onSaveDraft() {
      try {
        const errorsForm = await this.validateForm()
        if (errorsForm.message) {
          this.showInvalidDataMessage({
            message: errorsForm.message,
          })
          return
        }

        const confirmed = await this.confirm({
          title: this.$t('Confirma a criação do rascunho da Nota Fiscal?'),
          text: this.$t(''),
        })
        if (!confirmed) return

        this.loading = true
        const invoiceCreated = await this.saveInvoiceDraft()
        this.showSuccess({ message: this.$t('Salvo com sucesso.') })
        await this.cleanState()
        if (!this.isEditPage)
          await this.$router.replace({
            name: 'invoice-maintain',
            params: { id: invoiceCreated.id },
          })
        await this.loadInvoice()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },
    async onSendInvoice() {
      const errorsForm = await this.validateForm()
      if (errorsForm.message) {
        this.showInvalidDataMessage({
          message: errorsForm.message,
        })
        return
      }

      const confirmed = await this.confirm({
        title: this.$t('INVOICE.VIEW.CONFIRM_SAVE.TITLE'),
        text: this.$t('INVOICE.VIEW.CONFIRM_SAVE.MESSAGE'),
      })
      if (!confirmed) return

      if (!this.invoice.payments?.length) {
        this.showError({
          title: this.$t('Dados inválidos'),
          message: this.$t(
            'Informe pelo menos um pagamento. Caso não haja pagamentos para a Nota Fiscal, selecione a forma de pagamento "Sem Pagamento".'
          ),
        })
        return
      }

      let invoiceCreated

      try {
        this.loading = true
        invoiceCreated = await this.saveInvoiceDraft()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }

      if (invoiceCreated) {
        try {
          this.loading = true
          const retSend = await this.stInvoiceManage({
            requestType: invoiceDomains.data().invoiceRequestTypeEnum.GENERATE_AND_SEND_INVOICE,
            invoiceId: invoiceCreated.id,
          })

          if (retSend && retSend.status === 'SUCCESS_FINISHED') {
            this.showSuccess({ message: this.$t('Nota Fiscal enviada com sucesso.') })
          } else if (retSend.status === 'WAITING_FOR_RESPONSE') {
            this.showWarning({
              message: this.$t(
                'Nota fiscal pendente de resposta da Sefaz. Consulte a nota fiscal para atualizar o status!'
              ),
            })
          } else {
            this.showError(new Error(retSend.nfeDetail.returnMessage))
          }
        } catch (error) {
          const errorData = getApiErrorData(error)
          const delegableValue = delegablePermissions.ERP_STOCK_INVENTORY_DIFF_LIMIT_ON_FINISH
          if (
            errorData.exceptionType === exceptionTypeEnum.PERMISSION_DENIED &&
            delegableValue.relatedExceptions?.includes(errorData.message)
          ) {
            const confirmCallback = async modalDelegation => {
              const afterAuthConfirmed = await this.confirm({
                title: this.$t('INVOICE.VIEW.CONFIRM_SAVE.TITLE'),
                text: this.$t('INVOICE.VIEW.CONFIRM_SAVE.MESSAGE'),
              })
              if (!afterAuthConfirmed) return

              await this.onConsultProtocol(
                invoiceCreated.id,
                invoiceDomains.data().invoiceRequestTypeEnum.GENERATE_AND_SEND_INVOICE,
                modalDelegation
              )
            }

            this.modalAuthRef.authSync(delegableValue, invoiceCreated.storeId, {
              confirmCallback,
              withHasPermission: false,
              errorData,
            })
          } else {
            this.showError({ error })
          }
        } finally {
          this.loading = false
        }

        if (!this.isEditPage) {
          await this.$router.replace({
            name: 'invoice-maintain',
            params: { id: invoiceCreated.id },
          })
        }
        await this.loadInvoice()
      }
    },
    async onDeleteInvoice(){
      const confirmed = await this.confirm({
        title: this.$t('ATENÇÃO: Confirma a deleção deste rascunho da nota fiscal?'),
        text: this.$t('Não será possível visualizar os dados da nota após esta ação.'),
      })
      if (!confirmed) return

      try {
        this.loading = true
        await this.$http.delete(`/api/invoices/${this.invoice.id}`)
        this.showSuccess({ message: this.$t('Nota fiscal deletada com sucesso') })
        await this.$router.replace({
            name: 'invoice-list',
        })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },
    async onInvalidateInvoice() {
      const confirmed = await this.confirm({
        title: this.$t('ATENÇÃO: Confirma a inutilização desta numeração na Sefaz?'),
        text: this.$t('A numeração não poderá mais ser utilizada após esta ação.'),
      })
      if (!confirmed) return

      try {
        this.loading = true
        await this.invalidateInvoice()
        this.showSuccess({ message: this.$t('Numeração inutilizada com sucesso') })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },
    async onConsultInvoice() {
      await this.$refs.consultInvoiceModal.show(this.invoice)
    },
    async onConsultProtocol(invoiceId, requestType, delegateData) {
      try {
        this.loading = true
        const retSend = await this.stInvoiceManage({
          requestType: requestType || invoiceDomains.data().invoiceRequestTypeEnum.CONSULT_PROTOCOL,
          invoiceId: invoiceId || this.invoice.id,
          delegation: delegateData,
        })

        if (retSend && retSend.status === 'SUCCESS_FINISHED') {
          this.showSuccess({ message: this.$t('Nota Fiscal enviada com sucesso.') })
        } else if (retSend.status === 'WAITING_FOR_RESPONSE') {
          this.showWarning({
            message: this.$t(
              'Nota fiscal pendente de resposta da Sefaz. Consulte a nota fiscal para atualizar o status!'
            ),
          })
        } else {
          this.showError(new Error(retSend.nfeDetail.returnMessage))
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }

      await this.loadInvoice()
    },
    async onDownloadPdf() {
      try {
        const confirmed = await this.confirm({
          title: this.$t('Confirma o download do PDF da nota?'),
        })
        if (!confirmed) return

        this.loading = true

        const ret = await this.stInvoiceDownload({
          pathUrl: this.invoice.nfeDetail?.danfeNFePath,
        })

        if (ret.responsePayload && ret.responsePayload !== '') {
          this.forceFileDownloadFromBase64(
            ret.responsePayload,
            this.invoice.nfeDetail?.accessKey,
            'pdf'
          )
          this.showSuccess({ message: this.$t('Sucesso.') })
        } else {
          this.showError('Ocorreu um erro ao realizar o download da NFe!')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },
    async onDownloadXml() {
      try {
        const confirmed = await this.confirm({
          title: this.$t('Confirma o download do XML'),
        })
        if (!confirmed) return

        this.loading = true

        const ret = await this.stInvoiceDownload({
          pathUrl:
            this.invoice.nfeDetail?.xmlFinishedSuccessPath || this.invoice.satDetail?.cfeXmlPath,
        })

        if (ret.responsePayload && ret.responsePayload !== '') {
          this.forceFileDownloadFromBase64(
            ret.responsePayload,
            this.invoice.nfeDetail?.accessKey || this.invoice.satDetail?.cfeAccessKey,
            'xml'
          )
          this.showSuccess({ message: this.$t('Sucesso.') })
        } else {
          this.showError('Ocorreu um erro ao realizar o download da NFe')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },
    async onGenerateInvoiceComplementary() {
      const confirmed = await this.confirm({
        title: this.$t('Confirma a criação de uma nota complementar'),
      })
      if (!confirmed) return

      try {
        this.loading = true
        const retSend = await this.stGenerateComplementary({
          invoiceId: !this.invoice.id,
        })

        if (retSend && retSend.id > 0) {
          await this.$router.replace({
            name: 'invoice-maintain',
            params: { id: retSend.id },
          })
          await this.loadInvoice()
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },

    async onEventInvoice(type) {
      if (type === 'cancel' && this.isSaleFromPdvCategory) {
        this.showError({
          title: this.$t('Cancelamento não permitido'),
          message: this.$t(
            'Para cancelar uma NF-e gerada pelo PDV, utilize a tela Gerenciar Vendas no PDV!'
          ),
        })
        return
      }

      const eventLabelData = {
        cancel: {
          modalTitle: this.$t('Dados para o cancelamento'),
          validation: 'required|min:15|max:255',
          eventType: invoiceDomains.data().invoiceEventTypeEnum.CANCEL_INVOICE,
          confirmationOptions: {
            title: this.$t('ATENÇÃO: Confirma cancelar a Nota Fiscal?'),
            text: this.$t('A Nota fiscal será cancelada e não poderá reverter esta ação.'),
          },
          successOptions: {
            message: this.$t('Nota Fiscal cancelada com sucesso.'),
          },
        },
        correctionLetter: {
          modalTitle: this.$t('Dados para a carta de correção'),
          validation: 'required|min:15|max:1000',
          eventType: invoiceDomains.data().invoiceEventTypeEnum.SEND_CORRECTION_LETTER,
          confirmationOptions: {
            title: this.$t('Confirma criar uma carta de correção?'),
            text: this.$t(''),
          },
          successOptions: { message: this.$t('Carta de correção criada com sucesso.') },
        },
      }

      try {
        const eventLabel = eventLabelData[type]
        if (!eventLabel) throw new Error(this.$t('Evento não encontrado'))

        this.modalInvoiceEventTitle = eventLabel.modalTitle
        this.modalInvoiceEventJustificationValiation = eventLabel.validation
        const confirmed = await this.confirm(eventLabel.confirmationOptions)
        if (!confirmed) return

        await this.$refs.modalEventInvoice
          .show(() => {})
          .catch(() => {
            throw new EvalError()
          })

        this.loading = true
        const invoiceEventRes = await this.stInvoiceEvent({
          invoiceId: this.invoice.id,
          eventType: eventLabel.eventType,
          justification: this.invoiceEventForm.justification,
        })

        if (
          invoiceEventRes?.status !== this.statusInvoiceEnum.SUCCESS_FINISHED &&
          invoiceEventRes.returnMessage
        ) {
          this.showError({ message: invoiceEventRes.returnMessage })
        } else {
          this.showSuccess(eventLabel.successOptions)
        }

        const invoiceData = {
          nfeDetail: {
            returnCode: invoiceEventRes.returnCode,
            returnMessage: invoiceEventRes.returnMessage,
          },
        }
        await this.$refs.consultInvoiceModal.show(null, invoiceData)

        await this.loadInvoice()
        this.cleanInvoiceEventForm()
      } catch (error) {
        this.cleanInvoiceEventForm()
        if (error instanceof EvalError) {
          this.showWarning({ message: this.$t('Operação cancelada') })
        } else {
          this.showError({ error })
        }
      } finally {
        this.loading = false
      }
    },
    async onCancelSubmit() {
      const isConfirmed =
        this.readOnly ||
        (await this.confirm({
          title: this.$t('Deseja mesmo voltar?'),
          text: this.$t('Os dados preenchidos serão perdidos'),
        }))
      if (isConfirmed) {
        this.cleanState()
        this.$router.push({ name: 'invoice-list' })
      }
    },

    async validateForm() {
      const error = { message: '' }

      this.$refs.formInvoice.showErrors()
      if (this.$refs.invoiceDataForm.validate()) {
        error.message = this.$t('Em Dados da Nota Fiscal.')
      }

      const isRequiredCustomerId =
        this.isSaleCategory ||
        this.isStoreCategory ||
        this.isStockLossesCategory ||
        this.isProductBonusCategory ||
        this.isStoreLendingCategory ||
        this.isInvoiceFromCouponCategory

      if (this.$refs.invoiceValueForm.validate()) {
        error.message = this.$t('Em Valores da Nota Fiscal')
      } else if (this.isReturnCategory && !this.invoice.supplierId) {
        error.message = this.$t('Em Destinatário')
      } else if (isRequiredCustomerId && !this.invoice.customerId) {
        error.message = this.$t('Em Destinatário')
      } else if (this.$refs.invoiceItemsTable.validate()) {
        error.message = this.$t('Em items do pedido')
      } else if (this.$refs.invoicePaymentsTable && this.$refs.invoicePaymentsTable.validate()) {
        error.message = this.$t('Em pagamentos')
      }

      return error
    },

    isOutput() {
      if (this.invoice.movementType === 'OUTPUT') {
        return true
      }

      return false
    },

    async getPersonById(val, type) {
      if (this.loading) return
      try {
        this.loadingPerson = true
        await this.stFetchPersonById({ id: val, type })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingPerson = false
      }
    },

    async getPersonByDocument(val) {
      if (this.loading) return
      try {
        this.loadingPerson = true
        await this.stFetchPersonByDocument({ document: val })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingPerson = false
      }
    },

    cleanInvoiceEventForm() {
      this.invoiceEventForm = {
        justification: '',
      }
      this.modalInvoiceEventTitle = ''
    },
    async onChangeStore() {
      if (this.invoice.items.length === 0 || this.loading) return

      try {
        this.loadingItems = true
        await this.stUpdateItemsOfStore()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingItems = false
      }
    },
    async getStoreById(val) {
      if (this.loading) return
      try {
        this.loadingInvoiceData = true
        await this.stFetchStoreById(val)

        if (this.isStockLossesCategory) {
          this.getPersonByDocument(this.invoice?.store?.cnpj)
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingInvoiceData = false
      }

      await this.onChangeStore()
    },
  },
}
</script>

<style lang="scss" scoped>
/* .h-345 .card {
  min-height: 345px;
} */
</style>
